import { validateNotEmptyOrWhiteSpace, validateSqlServerDecimal } from "./util";
import { Validators } from "@angular/forms";

export const liabilityInterestRateDecimalPlaces = 3;
export const liabilityTaxDeductibilityDecimalPlaces = 3
export const superGuaranteePercentageDecimalPlaces = 2;
const minPercentValue = 0;
const maxPercentValue = 100;
export const percentValidator = [Validators.min(minPercentValue), Validators.max(maxPercentValue)];
// eslint-disable-next-line no-magic-numbers
export const sqlServer_18_2_DecimalValidator = validateSqlServerDecimal(18, 2);
// eslint-disable-next-line no-magic-numbers
export const sqlServer_18_4_DecimalValidator = validateSqlServerDecimal(18, 4);
export const stringNotEmptyOrWhitespaceValidator = validateNotEmptyOrWhiteSpace();